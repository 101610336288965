
import Vue from "vue";
import { mapGetters } from "vuex";

export default Vue.extend({
  name: "DiscoverMentorItem",
  props: ["user"],
  computed: {
    ...mapGetters({
      profile: "profile/getProfile",
      mentorcasts: "search/getMentorcasts"
    })
  },
  methods: {
    getCategory(data: any): any {
      // @ts-ignore
      if (data?.length) {
        // @ts-ignore
        return data.join(", ");
      }
    },
    routerLink() {
      if (this.profile?.id) {
        this.$router.push(`/mentors/${this.user.uuid}`);
      } else {
        this.$bvModal.show("login");
      }
    }
  }
});
