
import Vue from "vue";
import DiscoverMentorItem from "@/components/Discover/DiscoverMentorItem.vue";

export default Vue.extend({
  name: "DiscoverMentorItems",
  components: {
    DiscoverMentorItem
  },
  props: { users: { type: Array, default: null, required: false } }
});
