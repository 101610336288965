
import Vue from "vue";
import { mapGetters } from "vuex";

export default Vue.extend({
  name: "SearchMentor",
  props: {
    isTopMentor: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      filter: {
        searchVal: "",
        language_id: 1,
        order_by: "followers"
      },
      orderOptions: [] as any,
      // Select icons
      OpenIndicator: {
        render: (createElement: any) =>
          createElement("inline-svg", {
            attrs: {
              src: require(`@/assets/icons/arrow.svg`)
            }
          })
      }
    };
  },
  computed: {
    ...mapGetters({
      searchLanguage: "helper/getSearchLanguage",
      languages: "helper/getLanguages"
    })
  },
  created() {
    this.$store.dispatch("helper/GET_LANGUAGES");
    this.setOrderOptions();

    this.setOrderOptions();
  },
  async mounted() {
    await this.$store.dispatch("helper/GET_SEARCH_LANGUAGE");
    this.setSearchLanguage();
  },
  methods: {
    emitSearch() {
      this.$emit("search", this.filter, true);
    },
    setOrderOptions() {
      this.orderOptions = [
        {
          id: "mentor-rating",
          name: (this as any).$t(
            "discover.discoverMentorFilterOptionMentorRating"
          )
        },
        {
          id: "people-mentored",
          name: (this as any).$t(
            "discover.discoverMentorFilterOptionPeopleMentored"
          )
        },
        {
          id: "mentorcast-hosted",
          name: (this as any).$t(
            "discover.discoverMentorFilterOptionMentorcastHosted"
          )
        },
        {
          id: "followers",
          name: (this as any).$t("discover.discoverMentorFilterOptionFollowers")
        }
      ];
    },

    setSearchLanguage() {
      console.log("searchLanguage", this.searchLanguage);
      this.filter.language_id = this.searchLanguage.id;
    }
  }
});
