
import Vue from "vue";
import SearchMentor from "@/components/Discover/SearchMentor.vue";
import { mapGetters } from "vuex";
import DiscoverMentorItems from "@/components/Discover/DiscoverMentorItems.vue";
import DiscoverMentorItemsLoading from "@/components/Discover/DiscoverMentorItemsLoading.vue";
import LoginModal from "@/components/Modals/LoginModal.vue";

export default Vue.extend({
  name: "DiscoverMentorsPage",
  components: {
    SearchMentor,
    DiscoverMentorItems,
    DiscoverMentorItemsLoading,
    LoginModal
  },
  data: function() {
    return {
      filter: {
        searchVal: "",
        language_id: 1,
        order_by: "followers"
      },
      data: [] as any,
      page: 1,
      last_page: 0,
      load_more: false,
      isTopMentor: true,
      loading: false
    };
  },
  computed: {
    ...mapGetters({
      moduleSettings: "helper/getModuleSettings",
      users: "search/getUsers"
    }),
    mentorcastStatus(): any {
      return this.moduleSettings["mentorcast"] == 1;
    }
  },
  mounted() {
    this.$store.dispatch("helper/GET_MODULE_SETTINGS");
    this.onSearch(1, true);
  },
  methods: {
    onFilter(filter: any) {
      this.filter = filter;
      this.isTopMentor = false;
      this.onSearch(1, true);
    },
    onSearch(page: any, isDefault = false) {
      if (this.loading) {
        return;
      }
      if (isDefault) {
        this.data = [];
        this.last_page = 0;
        this.page = 1;
      }

      this.loading = true;

      this.$store
        .dispatch("search/SEARCH_MENTORS", {
          filter: {
            ...this.filter,
            isTopMentor: this.isTopMentor
          },
          page: this.page
        })
        .then(data => {
          this.page = page;
          this.load_more = page < data.last_page;
          this.data.push(...data.data);
          this.loading = false;
        });
    },
    onLoadMore() {
      this.page++;
      this.onSearch(this.page);
    }
  }
});
